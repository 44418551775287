var heroSlider = new Swiper('.heroSlide', {
    disableOnInteraction: false,
    loop: true,
    loopedSlides: 0,
    spaceBetween: 0,
    speed: 1000,
    effect: 'fade',
    autoplay: {
        delay: 5000
    },
    fadeEffect: {
        crossFade: true
    },
    navigation: {
        nextEl: '.hs-next',
        prevEl: '.hs-prev',
    },
    pagination: {
        el: '.sldot',
        clickable: true
    }    
});

$(".heroSlide").hover(function() {
    heroSlider.autoplay.stop();
}, function() {
    heroSlider.autoplay.start();
});

var iconSlider = new Swiper('.iconSlider', {
    disableOnInteraction: false,
    slidesPerView: 4,
    spaceBetween: 30,
    loop: true,
    speed: 1000,
    autoplay: {
        delay: 4000
    },
    navigation: {
        nextEl: '.hs-nextnew',
        prevEl: '.hs-prevnew',
    },
    breakpoints: {
    // when window width is <= 320px
      420: {
        slidesPerView: 1,
        spaceBetween: 20
      },
      // when window width is <= 480px
      650: {
        slidesPerView: 2,
        spaceBetween: 15
      },
      
      // when window width is <= 640px
      1000: {
        slidesPerView: 3,
        spaceBetween: 20
      }
    }
    // ,
    // pagination: {
    //     el: '.sldotnew',
    //     clickable: true
    // }    
});
$(".iconSlider").hover(function() {
    iconSlider.autoplay.stop();
}, function() {
    iconSlider.autoplay.start();
});

var servicesSlider = new Swiper('.servicesSlider', {
    slidesPerView: 4,
    spaceBetween: 0,
    loop: true,
    speed: 1000,
    autoplay: {
        delay: 2000
    },
    navigation: {
        nextEl: '.hs-next',
        prevEl: '.hs-prev',
    },    
    spaceBetween: 30,
    freeMode: true,
    breakpoints: {
    // when window width is <= 320px
      430: {
        slidesPerView: 1,
        spaceBetween: 40
      },
      // when window width is <= 480px
      600: {
        slidesPerView: 2,
        spaceBetween: 15
      },
      // when window width is <= 640px
      1000: {
        slidesPerView: 3,
        spaceBetween: 30
      },
      // when window width is <= 640px
      768: {
        slidesPerView: 3,
        spaceBetween: 15
      }
    }
});
$(".servicesSlider").hover(function() {
    servicesSlider.autoplay.stop();
}, function() {
    servicesSlider.autoplay.start();
});


var testimonialsSlider = new Swiper('.testimonialsSlider', {
    disableOnInteraction: false,
    loopedSlides: 0,
    allowTouchMove: false,
    slidesPerView: 1,
    spaceBetween: 0,
    loop: true,
    speed: 500,
    autoplay: {
        delay: 4000
    },
    navigation: {
        nextEl: '.hs-next-t',
        prevEl: '.hs-prev-t',
    },
    spaceBetween: 30,
    freeMode: true    
});
$(".testimonialsSlider").hover(function() {
    testimonialsSlider.autoplay.stop();
}, function() {
    testimonialsSlider.autoplay.start();
});


jQuery(document).ready(function($){
  // init controller
  var controller = new ScrollMagic.Controller();

  var headPin = new ScrollMagic.Scene({
    triggerHook: 0,
    triggerElement: "#headerpin"
  })
    .setPin("#headerpin")
    .setClassToggle("#headerpin", "header-active")
    .addTo(controller);

  controller.updateScene(headPin, true);


  $('.animfadeIn').each(function(){
    var animdur = $(this).data("animdur") || 1;
    var hook = $(this).data("hook") || 0.8;
   var myScene = new ScrollMagic.Scene({
   triggerElement: this,
   triggerHook: hook,
   reverse: false
   })
   .setTween(TweenMax.fromTo(this, animdur, {autoAlpha:0, y: 10},{autoAlpha:1, y: 0}))
   .addTo(controller);
});

if($('.slideout-menu').hasClass('slideout-menu')){

var slideout = new Slideout({
   'panel': document.getElementById('panel'),
   'menu': document.getElementById('menumob'),
   'padding': 256,
   'tolerance': 70
});

var fixed = document.querySelector('.headermob');
slideout.on('translate', function(translated) {
  fixed.style.transform = 'translateX(' + translated + 'px)';
});

slideout.on('beforeopen', function () {
  fixed.style.transition = 'transform 300ms ease';
  fixed.style.transform = 'translateX(256px)';
  $('.hamburger').addClass('h-active');
});

slideout.on('beforeclose', function () {
  fixed.style.transition = 'transform 300ms ease';
  fixed.style.transform = 'translateX(0px)';
  $('.hamburger').removeClass('h-active');
});

slideout.on('open', function () {
  fixed.style.transition = '';
});

slideout.on('close', function () {
  fixed.style.transition = '';
});

}
$(".hero-row").height($(window).height());


//cb box hover effect
TweenMax.set($(".cb-text"), {autoAlpha:0, y:30});
//TweenMax.set($(".cb-layer"), {height:"auto"});
$(".card-box.has-text").hover(function() {
  TweenMax.to($(this).find(".cb-layer"), 0.8, {height: "100%"});
  TweenMax.to($(this).find(".cb-text"), 0.8, {autoAlpha: 1, y: 0});
}, function() {
  TweenMax.to($(this).find(".cb-layer"), 0.8, {height: "75px"});
  TweenMax.to($(this).find(".cb-text"), 0.8, {autoAlpha: 0, y: 30});
});
  
var cl = $(".main-nav .nav").children().clone(true);

$('#menumob').append(cl).wrapInner('<ul></ul>').find(".btn-primary").addClass('btn-primary-reverse');

  // mobile menu
  $('.hamburger').click(function (event) {
    $(this).toggleClass('h-active');
    slideout.toggle();
  });

$(".gotop").hide();
$(function () {
    $(window).scroll(function () {
      if ($(this).scrollTop() > 200) {
        $('.gotop').fadeIn();
      } else {
        $('.gotop').fadeOut();
      }
    });

    // scroll body to 0px on click
    $('.gotop a').click(function () {
      $('body,html').animate({
        scrollTop: 0
      }, 800);
      return false;
    });
  });

});
